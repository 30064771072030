<template>
  <SfTopBar class="topbar desktop-only">
    <template #left> </template>
    <template #center>
      <ul v-if="usps" class="topbar__usps">
        <li v-for="(usp, index) in usps" :key="index"><Checkmark /> {{ usp }}</li>
      </ul>
    </template>
    <template #right> </template>
  </SfTopBar>
</template>

<script setup lang="ts">
import Checkmark from '../assets/icons/checkmark.svg'

const { locale } = useI18n()

const { data } = await useFetch('/api/bloomreach/document', {
  query: { path: 'header/headerusp', locale: locale.value },
  key: 'headerusps',
  getCachedData: (key, NuxtApp) => NuxtApp.payload.data[key],
})

const usps = computed(() => {
  const content = data.value?.content[data.value?.document?.$ref.substring(9)].data || {}
  return Object.keys(content)
    .filter((key) => key.includes('message'))
    .sort(sortStrings)
    .map((key) => content[key])
})
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid var(--secondary-color);

  &__usps {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: var(--spacer-lg);

    > li {
      display: flex;
      align-items: center;
      font-size: var(--font-size-13);
      line-height: var(--global-line-height);

      svg {
        margin-right: var(--spacer-sm);
      }
    }
  }
}
</style>
