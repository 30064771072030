<template>
  <div v-if="component" class="seo-text" :class="{ 'has-edit-button': isPreview }">
    <div class="grid-container" :style="`--column-count: ${columnCount}`">
      <br-manage-content-button :content="content" />
      <div class="two-column-text" v-html="textPart1" />
      <div
        class="two-column-text"
        :class="{
          'is-invisible': isInvisible && ((hideSecondPartMobile && isMobile) || (hideSecondPartDesktop && !isMobile)),
        }"
        v-html="textPart2"
      />
      <div class="two-column-text" :class="{ 'is-invisible': isInvisible }" v-html="textPart3" />
      <div class="two-column-text" :class="{ 'is-invisible': isInvisible }" v-html="textPart4" />
      <BloomreachFaq v-if="faqRef" class="two-column-text" :class="{ 'is-invisible': isInvisible }" :faqRef="faqRef" />
      <button
        class="link sf-button sf-button--text"
        :class="!showButton ? 'seo-text__hidden' : ''"
        @click="isInvisible = !isInvisible"
      >
        {{ isInvisible ? $t('Read more') : $t('Read less') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  component: null,
  page: null,
})

const { isMobile } = useDevice()
const isInvisible = ref(true)

const { document } = props.component?.getModels()

const isFullWidth = ref(props.page.getContent(document)?.getData()?.fullWidth)

const columnCount = ref(isFullWidth.value ? 1 : 2)
const content = ref(props.page.getContent(document))
const isPreview = ref(props.page?.isPreview())
const textPart1 = ref(props.page.getContent(document)?.getData()?.part1?.value)
const textPart2 = ref(props.page.getContent(document)?.getData()?.part2?.value)
const textPart3 = ref(props.page.getContent(document)?.getData()?.part3?.value)
const textPart4 = ref(props.page.getContent(document)?.getData()?.part4?.value)
const faqRef = ref(props.page.getContent(document)?.getData()?.faq?.$ref)
const hideSecondPartMobile = ref(props.page.getContent(document)?.getData()?.hideSecondPartMobile)
const hideSecondPartDesktop = ref(props.page.getContent(document)?.getData()?.hideSecondPartDesktop)

const showButton = computed(
  () =>
    (textPart1.value && textPart3.value) ||
    (textPart1.value && textPart2.value && isMobile && hideSecondPartMobile.value) ||
    (textPart1.value && textPart2.value && !isMobile && hideSecondPartDesktop.value),
)
</script>

<style lang="scss">
.hst-container-item:has(.seo-text) {
  grid-column: 1 / -1;
}

.seo-text {
  position: relative;
  margin: 0;
  padding: var(--spacer-lg) 0;
  background-color: var(--gray-background-color);

  @include for-desktop {
    padding: var(--spacer-lg) 0;
    width: 100%;
  }

  a,
  .link {
    display: inline-block;
    text-underline-offset: 2px;
    text-decoration: underline;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      color: var(--cta-pink-color);
    }
  }

  .link {
    margin: 12px 0 var(--spacer-md);
    appearance: none;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }

  p {
    @include for-desktop {
      column-count: var(--column-count);
      column-gap: 20px;
    }
  }

  &__hidden {
    display: none !important;
  }
}

.two-column-text {
  &.is-invisible {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}
</style>
