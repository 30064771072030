<template>
  <ul
    v-if="items.length"
    ref="nestedMenu"
    class="top-nav-nested-menu"
    :class="menuClassName"
    :style="{ left: offsetLeft + 'px' }"
  >
    <li
      v-if="title"
      class="nav-item nav-item__title"
      :class="{ 'nav-item__back-link': showBackLink }"
      :role="showBackLink ? 'button' : null"
      @click="collapse"
    >
      <ChevronLeftIcon v-if="showBackLink" />
      <span v-html="formatLink(title).name"></span>
    </li>

    <TopNavMenuItem
      v-for="(item, key) in items"
      :key
      ref="top-nav-menu-items"
      :index="`${menuId}_${key + 1}`"
      :item
      :level
    />
  </ul>
</template>

<script setup>
import ChevronLeftIcon from '~/assets/icons/chevron-left-alt.svg'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
  menuId: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  offsetLeft: {
    type: Number,
    default: 0,
  },
})

const topNavMenuItems = useTemplateRef('top-nav-menu-items')
const nestedMenu = ref()
const isExpanded = ref(false)
const showBackLink = computed(() => props.level > 2)

const menuClassName = computed(() => {
  return [`top-nav-nested-menu--level${props.level}`, isExpanded.value && 'top-nav-nested-menu--expanded']
})

const formatLink = (name) => {
  // name + label e.g.: "rokjes {deal}"
  return {
    name: name.replace(/ *\{[^)]*\} */g, ''),
    label: name.match(/{(\w+)}/, '$1')?.[1],
  }
}

const collapse = (event) => {
  event.preventDefault()
  isExpanded.value = false
}

const toggle = () => {
  isExpanded.value = !isExpanded.value

  // If this menu is higher than its parent, set the parent's height to this menu's height
  const heights = []
  let parentNestedMenu = nestedMenu.value?.parentNode?.parentNode
  while (parentNestedMenu.classList.contains('top-nav-nested-menu')) {
    heights.push(parentNestedMenu.offsetHeight)
    if (!parentNestedMenu.parentNode.parentNode.classList.contains('top-nav-nested-menu')) {
      break
    }
    parentNestedMenu = parentNestedMenu.parentNode.parentNode
  }

  const thisMenuHeight = nestedMenu.value.offsetHeight
  if (thisMenuHeight > Math.min(...heights)) {
    // this menu is taller than the menus above it
    parentNestedMenu.style.height = `${thisMenuHeight}px`
  }
}

const close = () => {
  isExpanded.value = false
  topNavMenuItems.value?.forEach((item) => {
    item.close()
  })
  document.querySelectorAll('.top-nav-nested-menu').forEach((menu) => {
    menu.style.height = null
  })
}

defineExpose({
  toggle,
  close,
})
</script>

<style lang="scss">
.top-nav-nested-menu {
  position: absolute;
  overflow: hidden;
  top: initial;
  left: 0;
  z-index: 1;
  padding: var(--spacer-xl);
  width: 504px;

  display: block;
  background: var(--white-color);
  color: var(--black-color);
  list-style: none;
  transition: transform 0.25s ease;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: var(--primary-color);
    height: var(--spacer-xs);
    pointer-events: none;
  }

  &--level2 {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 4px 11px rgba(29, 31, 34, 0.1);
  }

  ul.top-nav-nested-menu {
    transform: translateX(100%);

    &--expanded {
      transform: translateX(0);
    }
  }

  li {
    position: static;
    line-height: var(--global-line-height);
    border-bottom: 1px solid var(--gray-dark-accent-color);
    margin-right: 0;

    &.nav-item__title {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      font-family: var(--font-family--secondary);
      font-size: var(--h2-font-size);
      font-weight: var(--font-weight--normal);
      line-height: var(--sm-line-height);
      border-bottom: 0;
      height: auto;
      padding-bottom: var(--spacer-md);

      svg {
        margin-top: var(--spacer-2xs);
        margin-right: var(--spacer-base);
      }
    }

    &.nav-item__back-link {
      cursor: pointer;
    }

    .nav-link {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      color: var(--black-color);
      cursor: pointer;
      padding: var(--spacer-sm) 0;

      button {
        display: block;
        flex: 0 0 var(--spacer-md);
        padding: 0;
        border: 0;
        margin: 0;
        appearance: none;
        background: none;
        pointer-events: none;
        height: var(--spacer-md);
      }

      .nav-link__icon {
        display: block;
      }
    }

    &:hover {
      > .nav-link {
        color: var(--secondary-color);
      }
    }

    &.active {
      > .nav-link {
        color: var(--secondary-color);
      }
    }

    ul {
      top: 0;

      &.top-nav-nested-menu--expanded {
        height: 100%;
      }
    }
  }
}
</style>
