<template>
  <nav class="footer-menu" :class="{ 'has-edit-button': page.isPreview() }">
    <br-manage-menu-button :menu="menu" />
    <FooterMenuNested v-if="menu" :items="menu.getItems()" />
  </nav>
</template>

<script setup lang="ts">
import type { Component as BrComponent, Page } from '@bloomreach/spa-sdk'

const props = defineProps({
  component: {
    type: Object as () => BrComponent,
    required: true,
  },
  page: {
    type: Object as () => Page,
    required: true,
  },
})

const { menu } = useMenu(props)
</script>
