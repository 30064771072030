<template>
  <div>
    <br-manage-content-button :content="content" />
    <div class="mj-product-carousel" :class="'mj-product-carousel__background-grey'">
      <div class="container">
        <header v-if="title" class="mj-product-carousel__header">
          <h2>{{ title }}</h2>
        </header>
        <ProductsCarousel :products="products" :loading="isLoading" :centerInsufficientSlides="true" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Component, Page } from '@bloomreach/spa-sdk'

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()

const { getWidgetProducts } = useBloomreachWidgetsApi()

const { document } = props.component?.getModels()
const content = ref(props.page.getContent(document))
const pageData = ref(content.value?.getData())

const title = pageData.value!.title

const isLoading = ref(false)
const products = ref([])

const loadProducts = async () => {
  isLoading.value = true

  products.value = await getWidgetProducts({
    widgetType: pageData.value!.widgetType?.selectionValues?.[0]?.key,
    widgetId: pageData.value!.widgetID,
  })

  isLoading.value = false
}

onMounted(loadProducts)
</script>

<style scoped lang="scss"></style>
