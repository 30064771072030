<template>
  <div>
    <SfMegaMenu class="search" :visible="visible" :title="$t('Search results')">
      <transition name="sf-fade" mode="out-in">
        <SfLoader v-if="loading && !hasSearchSuggestions" />
        <div v-else-if="hasSearchSuggestions" key="results" class="search__wrapper">
          <SfList v-if="suggestions.length" class="search__suggestions">
            <SfListItem v-for="(suggestion, key) in suggestions" :key="key" class="search__suggestions-list-item">
              <SfMenuItem @click.native.prevent="handleShowSearchResults(suggestion.displayText)">
                <template #label><span v-dompurify-html="suggestion.markedText" /></template>
                <template #mobile-nav-icon> &#8203; </template>
              </SfMenuItem>
            </SfListItem>
          </SfList>

          <div class="search__categories">
            <h5 v-if="categories && categories.length" class="h5 search__header">
              {{ $t('Categories') }}
            </h5>

            <SfList v-if="categories && categories.length" class="search__categories-list">
              <SfListItem v-for="(category, key) in categories" :key="key" class="search__categories-list-item">
                <SfLink
                  class="search__categories-list-item-link"
                  :title="category.label"
                  @click.native.prevent="showCategoryPage(category.id)"
                >
                  <span v-dompurify-html="category.markedLabel" class="search__categories-list-item-label" />
                </SfLink>
              </SfListItem>
            </SfList>
          </div>

          <div class="search__products">
            <h5 v-if="products && products.length" class="h5 search__header">
              {{ $t('Product suggestions') }}
            </h5>

            <div class="search__products-list">
              <ProductCard
                v-for="product in products"
                :key="product.sku"
                class="result-card"
                :product="product"
                :product-image-first="true"
              />

              <template v-for="item in customContentSearchResults" :key="item.url">
                <SearchBarContentSearchResult :url="item.url" :imageUrl="item.imageUrl" :title="item.title" />
              </template>
            </div>

            <div v-if="products && products.length" class="show-all">
              <SfLink class="sf-button sf-button--outline" @click="handleShowSearchResults(result.query)">
                {{ $t('Show all results') }}
              </SfLink>
            </div>
          </div>
        </div>

        <div v-else key="no-results" class="before-results">
          <p
            v-if="result"
            class="before-results__paragraph"
            v-html="$t('No products were found for the search query', { query: result ? result.query : '' })"
          />
        </div>
      </transition>
    </SfMegaMenu>
  </div>
</template>

<script setup lang="ts">
import type { CategorySearchQuery } from '@vue-storefront/magento-sdk'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  result: {
    type: Object,
    default: () => ({}),
  },
  maxNumSuggestions: {
    type: Number,
    default: 5,
  },
  maxNumCategories: {
    type: Number,
    default: 8,
  },
})

const localePath = useLocalePath()
const { locale } = useI18n()
const router = useRouter()
const emit = defineEmits(['SearchBar:toggle'])

const customContentSearch = await import(`./contentSearchResults_${locale.value.substring(0, 2)}.json`)

const customContentSearchResults = computed(() => {
  if (props.result?.query?.length >= 3) {
    return customContentSearch.default.filter((item) =>
      item.keywords.some((keyword) => keyword.toLowerCase() === props.result.query.toLowerCase()),
    )
  }
})

const products = computed(() => props.result?.products?.slice(0, 6 - (customContentSearchResults.value?.length || 0)))
const categories = computed(() =>
  props.result?.categories?.sort((a, b) => b.count - a.count).slice(0, props.maxNumCategories),
)
const suggestions = computed(() => props.result?.suggestions?.slice(0, props.maxNumSuggestions) || {})
const hasSearchSuggestions = computed(() =>
  Boolean(products.value?.length || suggestions.value?.length || customContentSearchResults.value?.length),
)

const onViewportChanged = () => {
  const viewport = (event?.target || window.visualViewport) as VisualViewport
  document.documentElement.style.setProperty('--viewport-height', `${viewport.height}px`)
}

onMounted(() => {
  window.visualViewport.addEventListener('scroll', onViewportChanged)
  window.visualViewport.addEventListener('resize', onViewportChanged)
  onViewportChanged()
})

onBeforeUnmount(() => {
  document.body.classList.remove('no-scroll')
})

const showCategoryPage = async (categoryId) => {
  const searchParams = {
    filters: {
      ids: { eq: categoryId },
    },
  }

  const { data }: { data: CategorySearchQuery } = await $fetch('/api/magento/categorySearch', {
    method: 'POST',
    body: {
      key: `categorySearch_${searchParams.filters?.ids?.eq}_${locale.value}`,
      queryVariables: searchParams,
      locale: locale.value,
    },
  })

  if (data) {
    const category = data?.categoryList?.[0]
    if (category) {
      await router.push(localePath(`/${category.url_path}${category.url_suffix}`))
    }
  }
}

const handleShowSearchResults = async (query: string) => {
  emit('SearchBar:toggle', false)
  await router
    .push({
      path: `${localePath('/catalogsearch/result/')}`,
      query: { q: query },
    })
    .catch(() => {})
}
</script>

<style lang="scss">
.search {
  position: fixed;
  right: 0;
  left: 0;
  top: 118px;
  bottom: 0;
  z-index: 3;
  overflow: scroll;
  transition: height 0.25s ease;
  -webkit-overflow-scrolling: touch;
  --mega-menu-column-header-border: 0;
  --mega-menu-column-header-margin: 0 0 var(--spacer-base);
  --mega-menu-column-header-padding: 0;
  --mega-menu-content-padding: var(--spacer-sm) 0 var(--spacer-lg);
  --mega-menu-bar-display: none;
  --mega-menu-height: auto;

  @include for-mobile {
    bottom: auto;
    height: calc(100vh - 118px);
    height: calc(100dvh - 118px);

    // Target iOS Safari only
    @supports (-webkit-touch-callout: none) {
      height: var(--viewport-height, calc(100vh - 118px));
      padding-bottom: 118px;
    }
  }

  @include for-desktop {
    --mega-menu-content-padding: var(--spacer-base) var(--spacer-sm) var(--spacer-lg);
    --mega-menu-height: auto;
    position: absolute;
    top: unset;
    bottom: unset;
    margin-top: unset;
    overflow: auto;
    max-height: calc(100vh - 118px);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-desktop {
      display: grid;
      grid-template-areas: 'suggestions products' 'categories products';
      grid-template-columns: 240px 1fr;
      grid-template-rows: min-content 1fr;
    }
  }

  .sf-mega-menu__menu {
    @include for-mobile {
      grid-column: 1 / -1;
    }
  }

  h5.search__header {
    margin: 0 0 var(--spacer-sm);

    @include for-mobile {
      font-size: 14px;
      line-height: 20px;
      margin-top: var(--spacer-base);
      margin-bottom: var(--spacer-xs);

      &--products {
        margin-bottom: var(--spacer-sm);
      }
    }
  }

  &__suggestions {
    --list-item-margin: 0 0 var(--spacer-10);

    @include for-mobile {
      order: 1;
      padding: 0 var(--spacer-15);
    }

    @include for-desktop {
      grid-area: suggestions;
      --list-margin: 0 0 var(--spacer-lg);
    }

    &-list-item {
      button {
        display: inline-block;
        text-align: left;
      }

      &--show-all {
        position: absolute;
        right: 0;

        button {
          display: flex;
          text-decoration: underline;
        }
      }
    }
  }

  &__categories {
    @include for-mobile {
      order: 3;
      padding: 0 var(--spacer-15);
    }

    @include for-desktop {
      grid-area: categories;
    }

    &-list {
      --list-item-margin: 0 0 8px;

      @include for-desktop {
        --list-item-margin: 0 0 var(--spacer-sm);
      }
    }

    &-list-item {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      &:after {
        content: '';
        display: block;
        clear: both;
      }

      .search__categories-list-item-link {
        position: relative;
        padding-left: var(--spacer-sm);
        cursor: pointer;

        &:before {
          content: '/';
          position: absolute;
          left: 0;
          width: var(--spacer-sm);
          font-weight: var(--font-weight--normal) !important;
          text-align: center;
        }

        &:not(:first-of-type):not(:last-of-type):not(:nth-child(2)) {
          display: none;
        }

        &:not(:first-of-type):not(:last-of-type) {
          flex: 0 0 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0;

          &::before {
            font-size: var(--font-size--xs);
          }

          &:after {
            content: '...';
            font-size: var(--font-size--xs);
          }
        }

        &:first-child {
          padding-left: 0;

          &:before {
            display: none;
          }
        }
      }

      .search__categories-list-item__count {
        margin-left: var(--spacer-2xs);
      }
    }
  }

  &__products {
    @include for-mobile {
      order: 2;
    }

    @include for-desktop {
      grid-area: products;
      margin-left: var(--spacer-md);
    }

    .h5 {
      @include for-mobile {
        padding: 0 0 0 var(--spacer-15);
      }
    }

    .sf-scrollable__content {
      @include for-mobile {
        padding: 0 0 0 var(--spacer-xs);
      }
    }
  }

  .sf-bar {
    display: none;
  }

  .product-card__wishlist-icon {
    display: none;
  }

  .sf-loader {
    min-height: 100px;

    @include for-desktop {
      min-height: 404px;
    }
  }
}

.show-all {
  text-align: center;
  margin-top: var(--spacer-xs);

  a {
    display: inline-block;
  }
}

.action-buttons {
  padding: var(--spacer-xl) var(--spacer-sm) var(--spacer-3xl);
  background: var(--c-white);
  width: 100%;
  &__button {
    width: calc(100% - 32px);
  }
}

.search__products-list {
  display: flex;
  flex-flow: row nowrap;

  @include for-mobile {
    padding: 0 var(--spacer-15);
    gap: var(--spacer-xs);
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .product-card {
      min-width: 140px;
      scroll-snap-align: start;
      scroll-margin-left: 15px;

      &:first-child {
        width: calc(100% + var(--spacer-xs));
      }
    }
  }

  @include for-desktop {
    display: grid;
    gap: var(--spacer-md);
    grid-template-columns: repeat(6, 1fr);
  }
}

.result-card {
  margin: 0;
}

.before-results {
  box-sizing: border-box;
  padding: var(--spacer-base) var(--spacer-sm) var(--spacer-2xl);
  width: 100%;
  text-align: center;
  @include for-desktop {
    padding: 0;
  }

  &__picture {
    --image-width: 230px;
    margin-top: var(--spacer-2xl);
    @include for-desktop {
      --image-width: 18.75rem;
      margin-top: var(--spacer-base);
    }
  }

  &__paragraph {
    font-family: var(--font-family--primary);
    font-weight: var(--font-weight--normal);
    font-size: var(--font-size--base);
    line-height: 1.25;
    color: var(--c-black);
    margin: 0;

    strong {
      font-weight: var(--font-weight--semibold);
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: var(--cta-pink-color);
        text-decoration: underline;
      }
    }

    @include for-desktop {
      font-size: var(--font-size--lg);
    }

    &:first-of-type {
      margin: var(--spacer-xl) auto var(--spacer-xs);
    }
  }

  &__button {
    margin: var(--spacer-xl) auto;
    width: 100%;
  }
}
</style>
