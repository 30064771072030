<template>
  <SfOverlay class="mobile-nav-overlay" :visible="isMobileMenuOpen" @click="toggleNavMenu" />
  <transition name="slide-left">
    <nav v-if="isMobileMenuOpen" class="mobile-nav grid-container">
      <div class="mobile-nav__inner">
        <br-component component="menu" />
      </div>
      <SfButton class="sf-button--pure mobile-nav__close-button" @click="toggleNavMenu">
        <TimesIcon />
      </SfButton>
    </nav>
  </transition>
</template>

<script setup lang="ts">
import TimesIcon from '~/assets/icons/times.svg'

const uiState = useUiState()
const { toggleMobileMenu } = uiState
const { isMobileMenuOpen } = storeToRefs(uiState)

const toggleNavMenu = () => {
  toggleMobileMenu()
}
</script>

<style lang="scss">
.mobile-nav-overlay {
  --overlay-z-index: 10;
  --overlay-background: rgba(0, 0, 0, 0.6);
}

.mobile-nav {
  position: fixed;
  z-index: 10;
  display: flex;
  top: 0;
  height: 100vh;
  height: 100dvh;
  pointer-events: none;

  &__inner {
    position: relative;
    overflow: hidden;
    padding: 0 var(--spacer-sm) var(--spacer-sm);
    background-color: var(--white-color);
    width: calc(100vw - var(--spacer-xl));
    pointer-events: initial;
  }

  &__close-button {
    --button-border-radius: 0;
    --button-size: var(--spacer-xl);
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--primary-color);
    color: var(--black-color);
  }

  .nav-link,
  a {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    color: var(--black-color);
    padding: var(--spacer-sm) var(--spacer-xs) var(--spacer-sm) var(--spacer-sm);
  }

  .uitgelicht-item {
    background: var(--primary-color);
    color: var(--black-secondary-color);
    font-size: var(--font-size-xs);
    line-height: var(--global-line-height);
    text-transform: uppercase;
    text-wrap: nowrap;
    padding: var(--spacer-xs);
    border-radius: var(--spacer-2xs);
    pointer-events: none;
    z-index: 0;
    margin-left: var(--spacer-sm);
    margin-right: 0;
    display: inline-block;
    margin-top: -8px;
    margin-bottom: -8px;
  }
}
</style>
