<template>
  <div v-if="content" class="footer-cta-boutique" :class="{ 'has-edit-button': isPreview }">
    <h4>{{ content.title }}</h4>
    <p>{{ content.description }}</p>
    <a
      v-if="externalLink"
      class="sf-button sf-button--hollow action primary link--external"
      :href="externalLink"
      :aria-label="content.buttonLabel"
      target="_blank"
    >
      <BoutiqueIcon class="button__icon mobile-only" />
      {{ content.buttonLabel }}
    </a>
    <nuxt-link
      v-else
      class="sf-button sf-button--hollow action primary"
      :to="localePath(internalLink)"
      :title="content.buttonLabel"
      :aria-label="content.buttonLabel"
    >
      <BoutiqueIcon class="button__icon mobile-only" />
      {{ content.buttonLabel }}
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import BoutiqueIcon from '~/assets/icons/boutique.svg'

const props = defineProps({
  component: null,
  page: null,
})

const localePath = useLocalePath()

const content = computed(() => {
  const { document } = props.component?.getModels()
  const content = props.page?.getContent(document)
  return content?.model?.data || {}
})

const internalLink = computed(() => props.page?.getContent(content.value.ctaLink)?.getUrl())
const externalLink = computed(() => content.value?.ctaUrl || false)
const isPreview = computed(() => props.page?.isPreview())
</script>

<style lang="scss">
.footer-cta-boutique {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include for-desktop {
    align-items: center;
    text-align: center;
  }

  .sf-button {
    margin-top: auto;
  }
}
</style>
