<template>
  <li v-if="item" class="nav-item" :class="className" :aria-expanded="isExpanded">
    <NuxtLink
      class="nav-link"
      :class="[getLinkClassName(), { 'has-label': formattedLabel }]"
      :to="hasChildren() ? formattedUrl : null"
      :external="linkType !== TYPE_LINK_INTERNAL"
      :data-href="url"
      :data-menu="`menu-${index}`"
      @click.native="toggleExpanded"
    >
      <span v-html="formattedName"></span>
      <span v-if="formattedLabel" :class="[formattedClass, 'uitgelicht-item']">{{ formattedLabel }}</span>
      <ChevronRightIcon v-if="subMenuItems.length" class="nav-link__icon" />
    </NuxtLink>

    <MobileNavNestedMenu
      ref="submenu"
      :items="subMenuItems"
      :level="nextLevel"
      :menuId="`${index}`"
      :title="itemName"
      :isExpanded="isExpanded"
      @collapse="collapse"
    />
  </li>
</template>

<script setup>
import { TYPE_LINK_EXTERNAL, TYPE_LINK_INTERNAL } from '@bloomreach/spa-sdk'
import ChevronRightIcon from '~/assets/icons/chevron-right-alt.svg'

const mobileLabelIdentifier = 'mobile-only'
const desktopLabelIdentifier = 'desktop-only'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  index: {
    type: [Number, String],
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['opened', 'closed'])

const { toggleMobileMenu } = useUiState()
const isExpanded = ref(false)
const submenu = ref(null)

const sanitizeTitle = (name) => name.replace(/(<([^>]+)>)/gi, '').trim()

const url = props.item?.getUrl()
const formattedUrl = formatUrl(url)
const linkType =
  typeof formattedUrl === 'string' && formattedUrl.search(/(^\/|^#)/g) === -1 ? TYPE_LINK_EXTERNAL : TYPE_LINK_INTERNAL
const propsItemName = props.item?.getName()
const itemName = sanitizeTitle(propsItemName)
const subMenuItems = props.item?.getChildren() || []
const nextLevel = (props.level || 1) + 1

const hasChildren = () => !subMenuItems.length

const className = computed(() => {
  return {
    expanded: isExpanded.value,
    'has-children': subMenuItems.length,
    active: props.item?.isSelected(),
  }
})

const toggleExpanded = (event) => {
  if (hasChildren()) {
    toggleMobileMenu()
    return
  }

  event.preventDefault()
  isExpanded.value = !isExpanded.value
  emit(isExpanded.value ? 'opened' : 'closed')
}

const getLinkClassName = () => {
  return {
    'nav-link--disabled': !url,
    'nav-link--external': linkType === TYPE_LINK_EXTERNAL,
    'nav-link--internal': linkType === TYPE_LINK_INTERNAL,
    'nav-link--expandable': subMenuItems?.length,
  }
}

const formatLink = () => {
  const formattedName = propsItemName
    .replace(/\{(.*)\}/, '')
    .replace(/\_(.*)\_/, '<i>$1</i>')
    .replace(/\*(.*)\*/, '<b>$1</b>')
    .replace(/\~(.*)\~/, '<s>$1</s>')
    .replace(/\<p\>(.*)<\/p\>/, '$1')

  const label = propsItemName.match(/\{(.*)\}/, '$1')?.[1]
  if (!label) return { name: formattedName }

  let labelClass = ''
  if (label) {
    if (label.includes(mobileLabelIdentifier)) labelClass = mobileLabelIdentifier
    if (label.includes(desktopLabelIdentifier)) labelClass = desktopLabelIdentifier
  }
  const labelClean = label.replace(mobileLabelIdentifier, '').replace(desktopLabelIdentifier, '').trim()
  return {
    name: formattedName,
    label: labelClean,
    class: labelClass,
  }
}

const formattedName = formatLink().name
const formattedClass = formatLink().class
const formattedLabel = formatLink().label

const collapse = () => {
  isExpanded.value = false
}
</script>

<style lang="scss">
.nav-item {
  strong,
  b {
    font-weight: 800;
  }
}

.nav-link__icon {
  width: var(--spacer-base);
  height: var(--spacer-base);
  margin-top: -4px;
  margin-bottom: -4px;
}
</style>
