<template>
  <Teleport defer to=".mobile-nav__inner">
    <transition name="slide-right">
      <ul v-if="items.length && isExpanded" ref="submenu" class="mobile-nav-nested-menu" :class="menuClassName">
        <li
          v-if="title"
          class="nav-item nav-item__title"
          :class="{ 'nav-item__back-link': showBackLink }"
          :role="showBackLink ? 'button' : null"
          @click="collapse"
        >
          <ChevronLeftIcon v-if="showBackLink" />
          <span v-html="formatLink(title).name"></span>
        </li>

        <MobileNavMenuItem
          v-for="(item, index) in items"
          :key="index"
          :index="`${menuId}_${index + 1}`"
          :item="item"
          :level="level"
          @opened="openened"
        />
      </ul>
    </transition>
  </Teleport>
</template>

<script setup>
import ChevronLeftIcon from '~/assets/icons/chevron-left-alt.svg'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
  menuId: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  isExpanded: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['collapse'])

const submenu = ref()

const showBackLink = computed(() => props.level > 1)

const menuClassName = computed(() => {
  return [`mobile-nav-nested-menu--level${props.level}`, props.isExpanded && 'mobile-nav-nested-menu--expanded']
})

const formatLink = (name) => {
  // name + label e.g.: "rokjes {deal}"
  return {
    name: name.replace(/ *\{[^)]*\} */g, ''),
    label: name.match(/{(\w+)}/, '$1')?.[1],
  }
}

const collapse = (event) => {
  event.preventDefault()
  emit('collapse', true)
}

const openened = () => {
  submenu.value.scrollTop = 0
}
</script>

<style lang="scss">
.mobile-nav-nested-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - var(--spacer-xl));
  height: 100vh;
  height: 100dvh;
  max-height: 100vh;
  max-height: 100dvh;
  background: var(--white-color);
  color: var(--black-color);
  z-index: 1;
  list-style: none;
  margin: 0;
  padding: var(--spacer-sm);
  overflow: auto;

  @supports (-webkit-touch-callout: none) {
    padding-bottom: 6rem;
  }

  li {
    position: static;
    line-height: var(--global-line-height);
    border-bottom: 1px solid var(--gray-dark-accent-color);
    margin-right: 0;

    &.nav-item__title {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      font-family: var(--font-family--secondary);
      font-size: var(--h2-font-size);
      font-weight: var(--font-weight--normal);
      line-height: var(--sm-line-height);
      height: var(--spacer-xl);
      margin-bottom: var(--spacer-sm);
      border-bottom: 0;

      svg {
        margin-top: var(--spacer-2xs);
        margin-right: var(--spacer-sm);
      }
    }

    &.nav-item__back-link {
      height: var(--spacer-3rem);

      svg {
        margin: var(--spacer-2xs) var(--spacer-xs) 0 -8px;
      }
    }

    .nav-link {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      color: var(--black-color);
      padding: var(--spacer-18) var(--spacer-sm) var(--spacer-sm);

      button {
        display: block;
        flex: 0 0 var(--spacer-md);
        padding: 0;
        border: 0;
        margin: 0;
        appearance: none;
        background: none;
        pointer-events: none;
        height: var(--spacer-md);
      }

      .nav-link__icon {
        display: block;
      }
    }

    &.active {
      > .nav-link {
        color: var(--secondary-color);
      }
    }
  }
}
</style>
