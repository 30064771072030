<template>
  <li
    v-if="item"
    ref="topNavItem"
    class="nav-item"
    :class="className"
    @mouseenter="showAndMoveArrow"
    @mouseleave="hideArrow"
  >
    <NuxtLink
      class="nav-link"
      :class="[getLinkClassName(), { 'has-label': formattedLabel }]"
      :to="useNativeLinkEvent() ? formattedUrl : null"
      :external="linkType !== TYPE_LINK_INTERNAL"
      :data-href="url"
      :data-menu="`menu-${index}`"
      @click.native="toggleExpanded"
    >
      <span v-html="formattedName"></span>
      <span v-if="formattedLabel" :class="[formattedClass, 'uitgelicht-item']">{{ formattedLabel }}</span>
      <ChevronRightIcon v-if="subMenuItems.length && level > 1" class="nav-link__icon" />
    </NuxtLink>

    <TopNavNestedMenu
      ref="submenu"
      :items="subMenuItems"
      :level="nextLevel"
      :menuId="`${index}`"
      :title="itemName"
      :offsetLeft
    />
  </li>
</template>

<script setup>
import { TYPE_LINK_EXTERNAL, TYPE_LINK_INTERNAL } from '@bloomreach/spa-sdk'
import ChevronRightIcon from '~/assets/icons/chevron-right-alt.svg'

const { isDesktop } = useDevice()
const mobileLabelIdentifier = 'mobile-only'
const desktopLabelIdentifier = 'desktop-only'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  index: {
    type: [Number, String],
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
})

const topNavItem = ref()
const submenu = ref(null)

const sanitizeTitle = (name) => name.replace(/(<([^>]+)>)/gi, '').trim()

const url = props.item?.getUrl()
const formattedUrl = formatUrl(url)
const linkType =
  typeof formattedUrl === 'string' && formattedUrl.search(/(^\/|^#)/g) === -1 ? TYPE_LINK_EXTERNAL : TYPE_LINK_INTERNAL
const propsItemName = props.item?.getName()
const itemName = sanitizeTitle(propsItemName)
const subMenuItems = props.item?.getChildren() || []
const nextLevel = (props.level || 1) + 1

const offsetLeft = ref(0)

/**
 * Adjusts the position of expanded submenus to ensure they remain within the visible screen area.
 */
const setOffsetLeftForNestedMenu = () => {
  if (props.level === 1) {
    const offsetFromSideOfScreen = 20
    const menuWidth = topNavItem.value.querySelector('.top-nav-nested-menu')?.clientWidth
    const middleOfButton = topNavItem.value.offsetLeft + topNavItem.value.offsetWidth / 2
    const middleOfMenu = middleOfButton - menuWidth / 2
    if (middleOfMenu < 0) {
      // Would go off the left side of the screen
      offsetLeft.value = offsetFromSideOfScreen
    } else if (middleOfButton + menuWidth / 2 > window.innerWidth) {
      // Would go off the right side of the screen
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
      offsetLeft.value = window.innerWidth - menuWidth - offsetFromSideOfScreen - scrollbarWidth
    } else {
      // Position in the middle of the button
      offsetLeft.value = middleOfMenu
    }
  }
}

onMounted(setOffsetLeftForNestedMenu)

const hideArrow = () => {
  if (props.level === 1) {
    document.querySelector('.top-nav__arrow')?.classList.remove('open')
    close()
  }
}

const showAndMoveArrow = (event) => {
  const arrow = document.querySelector('.top-nav__arrow')
  const target = event.currentTarget
  const rect = target.getBoundingClientRect()

  if (subMenuItems?.length) {
    if (arrow) {
      arrow.classList.add('open')
      arrow.style.transform = `translate(${rect.x + rect.width / 2 - 10}px, 50px)`
    }
  } else {
    hideArrow()
  }
}

const useNativeLinkEvent = () => !subMenuItems.length || (isDesktop && props.level === 1)

const className = computed(() => ({
  'has-children': subMenuItems.length,
  active: props.item?.isSelected(),
}))

const toggleExpanded = (event) => {
  if (useNativeLinkEvent()) return

  event.preventDefault()
  submenu.value.toggle()
}

const getLinkClassName = () => ({
  'nav-link--disabled': !url,
  'nav-link--external': linkType === TYPE_LINK_EXTERNAL,
  'nav-link--internal': linkType === TYPE_LINK_INTERNAL,
  'nav-link--expandable': subMenuItems?.length,
})

const formatLink = () => {
  const formattedName = propsItemName
    .replace(/\{(.*)\}/, '')
    .replace(/\_(.*)\_/, '<i>$1</i>')
    .replace(/\*(.*)\*/, '<b>$1</b>')
    .replace(/\~(.*)\~/, '<s>$1</s>')
    .replace(/\<p\>(.*)<\/p\>/, '$1')

  const label = propsItemName.match(/\{(.*)\}/, '$1')?.[1]
  if (!label) return { name: formattedName }

  let labelClass = ''
  if (label) {
    if (label.includes(mobileLabelIdentifier)) labelClass = mobileLabelIdentifier
    if (label.includes(desktopLabelIdentifier)) labelClass = desktopLabelIdentifier
  }
  const labelClean = label.replace(mobileLabelIdentifier, '').replace(desktopLabelIdentifier, '').trim()
  return {
    name: formattedName,
    label: labelClean,
    class: labelClass,
  }
}

const formattedName = formatLink().name
const formattedClass = formatLink().class
const formattedLabel = formatLink().label

const close = () => submenu.value?.close()

defineExpose({
  close,
})
</script>

<style lang="scss">
.nav-item {
  strong,
  b {
    font-weight: 800;
  }
}
</style>
