<template>
  <div class="piercing-booking">
    <div ref="bookingElement" class="piercing-booking__module"></div>
  </div>
</template>

<script setup lang="ts">
import type { Component, Page } from '@bloomreach/spa-sdk'

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()
const logger = useAppLogger('PiercingBooking')

const { user: customerData, load } = useUser()
const bookingElement = ref(null)

const bookingUrl = props.component?.model?.meta?.paramsInfo?.bookingUrl

const addCalendlyScript = new Promise((resolve, reject) => {
  const calendlyUrl = 'https://assets.calendly.com/assets/external/widget.js'
  let calendly = document.createElement('script')
  calendly.setAttribute('src', calendlyUrl)
  document.head.appendChild(calendly)

  setTimeout(() => {
    const { Calendly } = window as any
    if (Calendly) {
      resolve(Calendly)
    } else {
      reject(new Error("Can't load Calendly"))
    }
  }, 300)
})

const initializeCalendy = (Calendly: any) => {
  if (!bookingUrl) return
  try {
    Calendly.initInlineWidget({
      url: bookingUrl,
      parentElement: bookingElement.value,
      prefill: {
        name:
          [customerData.value?.firstname, customerData.value?.middlename, customerData.value?.lastname]
            .filter((v) => v)
            .join(' ') || '',
        email: customerData.value?.email || '',
      },
      utm: {},
    })
  } catch (e) {
    logger.warn(e)
  }
}

onMounted(async () => {
  await load()
  addCalendlyScript.then((Calendly) => initializeCalendy(Calendly)).catch((e) => logger.warn(e))
})
</script>

<style lang="scss">
.piercing-booking {
  display: flex;
  min-height: 1212px;
  width: 100%;
  margin-bottom: var(--mj-component-margin-bottom);

  @include for-desktop {
    min-height: 800px;
  }

  &__module {
    width: 100%;
  }
}
</style>
